import './index.scss';

import React from "react";
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus, faClose} from "@fortawesome/free-solid-svg-icons";

import {PAGE_TITLE} from "../../tools";
import Me from "../../assets/me.png";
import {cleanUpDataBase} from "../../firebaseTools";

const Header = ({isEdit, setIsEdit, onLogout, setShowAddLink, showAddLink, setLinks, setCategories, setError}) => {

	const handleCleanUpDataBase = async () => {
		if (!window.confirm(`Are you sure you want to delete all links and categories ?`)) {
			return;
		}
		await cleanUpDataBase(setError);
		setLinks([]);
		setCategories([]);
	}

	return (
		<>
			<div className="left-btn-container">
				{isEdit ? (
					<button onClick={() => setShowAddLink(!showAddLink)} className="add-button">
						<FontAwesomeIcon icon={faCirclePlus}/>
					</button>
				) : (
					<button onClick={() => onLogout()} className="logout-button">
						<FontAwesomeIcon icon={faClose}/>
					</button>
				)
				}
			</div>
			<h1 onClick={handleCleanUpDataBase}>{PAGE_TITLE}</h1>
			<div className="right-btn-container">
				{isEdit ?
					<div onClick={() => setIsEdit(false)} className="close-button">
						<FontAwesomeIcon icon={faClose}/>
					</div>
					:
					<div onClick={() => setIsEdit(true)} className="edit-button">
						<img src={Me} alt="edit-button"/>
					</div>
				}
			</div>
		</>
	);
}

Header.propTypes = {
	isEdit: PropTypes.bool.isRequired,
	setIsEdit: PropTypes.func.isRequired,
	onLogout: PropTypes.func.isRequired,
	setShowAddLink: PropTypes.func.isRequired,
	showAddLink: PropTypes.bool.isRequired,
	setLinks: PropTypes.func.isRequired,
	setCategories: PropTypes.func.isRequired,
	setError: PropTypes.func.isRequired,
};

export default Header;
