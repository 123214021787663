import './index.scss';

import {FOOTER_TITLE, FOOTER_URL_LINK} from "../../tools";

const Footer = () => {
	return (
		<div className="footer-container">
			<a href={FOOTER_URL_LINK}> {FOOTER_TITLE} </a>
		</div>
	);
}

export default Footer;
