import './index.scss';

import React, {useMemo, useState} from "react";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsis, faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";

import {
	getFormattedValue,
	getLinksByCategories,
	isDuplicatedCategories,
	padCategories,
	removeCategoryFromFirebase,
	removeCategoryIfLastLink,
	removeLinkFromFirebase,
	sortCategories,
	updateCategoryIndices,
	updateLinksWhenCategoryChanged,
	verifyParamsRemoveCategory,
	verifyParamsSaveCategory,
	verifyParamsUpdateLink
} from '../../tools';
import {fetchCategory, removeLinksWhenCategoryRemoved, updateCategory, updateLink} from "../../firebaseTools";

import EditCategory from "../../components/EditCategory";
import EditLink from "../../components/EditLink";

const Body = ({isEdit, categories, setCategories, links, setLinks, error, setError}) => {
	const [editingCategoryObject, setEditingCategoryObject] = useState(null);
	const [editingLinkObject, setEditingLinkObject] = useState(null);

	const handleCloseModal = () => {
		setEditingCategoryObject(null);
		setEditingLinkObject(null);
	};

	const categoriesToDisplay = useMemo(() =>
			sortCategories(padCategories(categories)),
		[categories]
	);

	const linksByCategory = useMemo(() => {
		if (!links || !Array.isArray(links)) return {};
		return getLinksByCategories(categoriesToDisplay, links);
	}, [links, categoriesToDisplay]);

	const handleUpdateLink = async (link) => {
		const downloadUrl = await verifyParamsUpdateLink(link);
		if (downloadUrl === null) {
			handleCloseModal();
			return;
		}

		const linkId = link.id;
		const linkData = {
			category: link.category,
			name: link.name,
			url: link.url,
			image: downloadUrl
		};

		await updateLink(linkId, linkData, setError);

		setLinks((prevLinks) =>
			prevLinks.map((link) =>
				link.id === linkId ? {...link, ...linkData} : link
			)
		);
		handleCloseModal();
	};

	const handleRemoveLink = async (linkToRemove) => {
		if (!window.confirm(`Are you sure you want to delete "${linkToRemove.name}"?`)) {
			return;
		}

		const categoryToCheck = linkToRemove.category;
		const linkId = linkToRemove?.id ?? null;

		const linkRemoved = await removeLinkFromFirebase(linkToRemove, setError);
		if (linkRemoved) {
			setLinks(prevLinks =>
				prevLinks.filter(link => link.id !== linkId)
			);

			const linksToCheck = links.filter(link => link.category === categoryToCheck);
			if (linksToCheck.length < 2) {
				const updatedCategories = await removeCategoryIfLastLink(categories, categoryToCheck, setError);
				if (updatedCategories !== null) {
					setCategories(updatedCategories);
				}
			}
		}
		handleCloseModal();
	};

	const handleRemoveCategory = async (categoryData) => {
		if (!window.confirm(`Are you sure you want to delete the category "${categoryData.category}"?`)) {
			return;
		}

		const categoryId = verifyParamsRemoveCategory(categoryData, setError);
		if (categoryId === null) {
			handleCloseModal();
			return;
		}

		const categoryRemoved = await removeCategoryFromFirebase(categoryId, setError);
		if (categoryRemoved) {
			const category = categoryData.category;
			await removeLinksWhenCategoryRemoved(category, setError);

			setLinks(prevLinks =>
				prevLinks.filter(link => link.category !== category)
			);

			const updatedCategories = await updateCategoryIndices(categories, categoryData.id,
				null, null, setError, true);

			if (updatedCategories !== null) {
				setCategories(updatedCategories);
			}
		}
		handleCloseModal();
	};

	const handleSaveCategory = async (categoryData) => {
		const categoryId = verifyParamsSaveCategory(categoryData, categories, setError);
		if (categoryId === null) {
			setError(`Did not find the category with id: ${categoryData.id}`);
			return;
		}

		try {
			const categorySnapshot = await fetchCategory(categoryId, setError);
			if (categorySnapshot.exists()) {
				const oldCategory = categorySnapshot.data();

				const oldCategoryName = oldCategory.category;
				let newCategoryName = categoryData.category;

				if (newCategoryName !== oldCategoryName) {
					newCategoryName = getFormattedValue(newCategoryName);
				}

				const oldCategoryIdx = Number(oldCategory.idx);
				const newCategoryIdx = Number(categoryData.idx);

				const newCategoryData = {
					category: newCategoryName,
					idx: newCategoryIdx,
					id: categoryId
				}
				const isMergeCategory = isDuplicatedCategories(categories, newCategoryName);

				if (newCategoryIdx !== oldCategoryIdx && newCategoryName === oldCategoryName) {
					await handleIdxChangeOnly(categoryId, newCategoryIdx, oldCategoryIdx);
				} else if (newCategoryName !== oldCategoryName && newCategoryIdx === oldCategoryIdx
					&& !isMergeCategory) {
					await handleNameChangeOnly(categoryId, oldCategoryName, newCategoryName, newCategoryData);
				} else if (newCategoryName !== oldCategoryName && isMergeCategory) {
					await handleNameChangeAndMerge(categoryId, oldCategoryName, newCategoryName);
				} else if (newCategoryName !== oldCategoryName && newCategoryIdx !== oldCategoryIdx
					&& !isMergeCategory) {
					await handleIdxAndNameChange(categoryId, oldCategoryIdx, newCategoryIdx, oldCategoryName,
						newCategoryName, newCategoryData);
				}
			}
			handleCloseModal();
		} catch (error) {
			setError(`Error updating category: ${error.message}`);
		}
	};

	const handleIdxChangeOnly = async (categoryId, newCategoryIdx, oldCategoryIdx) => {
		const updatedCategories = await updateCategoryIndices(categories, categoryId, newCategoryIdx,
			oldCategoryIdx, setError);
		if (updatedCategories) {
			setCategories(updatedCategories);
		}
	};

	const handleNameChangeOnly = async (categoryId, oldCategoryName, newCategoryName, newCategoryData) => {
		const categoryUpdated = await updateCategory(newCategoryData, setError);
		if (categoryUpdated) {
			const updatedLinks = await updateLinksWhenCategoryChanged(links, oldCategoryName, newCategoryName,
				false, setError);
			if (updatedLinks.length > 0) {
				setLinks((prevLinks) =>
					prevLinks.map((link) => updatedLinks.find((updatedLink) => updatedLink.id === link.id)
						|| link)
				);
			}

			const otherCategories = categories.filter((c) => c.id !== categoryId);
			setCategories([...otherCategories, newCategoryData]);
		}
	};

	const handleNameChangeAndMerge = async (categoryId, oldCategoryName, newCategoryName) => {
		const updatedLinks = await updateLinksWhenCategoryChanged(links, oldCategoryName, newCategoryName,
			true, setError);
		if (updatedLinks.length > 0) {
			setLinks((prevLinks) =>
				prevLinks.map((link) => updatedLinks.find((updatedLink) => updatedLink.id === link.id)
					|| link)
			);
		}

		const categoryRemoved = await removeCategoryFromFirebase(categoryId, setError);
		if (categoryRemoved) {
			const updatedCategories = await updateCategoryIndices(categories, categoryId, null,
				null, setError, true);
			if (updatedCategories !== null) {
				setCategories(updatedCategories);
			}
		}
	};

	const handleIdxAndNameChange = async (categoryId, oldCategoryIdx, newCategoryIdx, oldCategoryName, newCategoryName,
	                                      newCategoryData) => {
		const updatedCategories = await updateCategoryIndices(categories, categoryId, newCategoryIdx,
			oldCategoryIdx, setError);
		if (updatedCategories) {
			const updatedLinks = await updateLinksWhenCategoryChanged(links, oldCategoryName, newCategoryName,
				false, setError);
			if (updatedLinks.length > 0) {
				setLinks((prevLinks) =>
					prevLinks.map((link) => updatedLinks.find((updatedLink) => updatedLink.id === link.id)
						|| link)
				);
			}

			const categoryUpdated = await updateCategory(newCategoryData, setError);
			if (categoryUpdated) {
				const otherCategories = updatedCategories.filter((c) => c.id !== categoryId);
				setCategories([...otherCategories, newCategoryData]);
			}
		}
	};

	return (
		<>
			{error && <div className="error-message">{error}</div>}
			<div className="categories-grid">
				{categoriesToDisplay.map((categoryObj) => (
					<div
						key={categoryObj.idx}
						className={`category-container ${categoryObj.category === "" ? "empty-category" : ""}`}
					>
						{categoryObj.category && (
							<>
								<div className="category-header">
									<h2 className="category-title">{categoryObj.category}</h2>
									{isEdit && (
										<FontAwesomeIcon icon={faEllipsis}
										                 className="category-icon"
										                 onClick={(e) => {
											                 e.preventDefault();
											                 setEditingCategoryObject(categoryObj);
										                 }}/>
									)}
								</div>
								{linksByCategory[categoryObj.category]?.map((link) => (
									<div className="link-container" key={link.id}>
										<a className="link"
										   rel="noopener noreferrer"
										   href={link.url}
										   target="_blank"
										>
											<img src={link.image} className="link-image" alt=""/>
											<span className="link-text">{link.name}</span>
										</a>
										{isEdit && (
											<FontAwesomeIcon icon={faEllipsisVertical}
											                 className="link-icon"
											                 onClick={(e) => {
												                 e.preventDefault();
												                 setEditingLinkObject(link);
											                 }}/>
										)}
									</div>
								))}
							</>
						)}
					</div>
				))}
			</div>

			{editingCategoryObject && (
				<EditCategory
					categories={categories}
					categoryObject={editingCategoryObject}
					onClose={handleCloseModal}
					onSave={handleSaveCategory}
					onRemove={handleRemoveCategory}
				/>
			)}

			{editingLinkObject && (
				<EditLink
					link={editingLinkObject}
					onClose={handleCloseModal}
					onSave={handleUpdateLink}
					onRemove={handleRemoveLink}
				/>
			)}
		</>
	);
};

Body.propTypes = {
	isEdit: PropTypes.bool.isRequired,
	categories: PropTypes.array.isRequired,
	setCategories: PropTypes.func.isRequired,
	links: PropTypes.array.isRequired,
	setLinks: PropTypes.func.isRequired,
	error: PropTypes.string.isRequired,
	setError: PropTypes.func.isRequired,
};

export default Body;
