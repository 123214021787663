import './index.scss';

import React, {useState} from "react";
import PropTypes from 'prop-types';

import {
	calculateNextIdx,
	generateUniqueLinkName,
	getFormattedValue,
	handleImageUpload,
	validateFormAddLink
} from '../../tools';
import {addLink, uploadNewCategory} from "../../firebaseTools";

const AddLink = ({categories, setCategories, links, setLinks, setShowAddLink, setError}) => {
	const [formData, setFormData] = useState({
		name: '',
		url: '',
		category: '',
		image: null
	});
	const [suggestions, setSuggestions] = useState([]);
	const [showSuggestions, setShowSuggestions] = useState(false);

	const handleAddCategory = async (category) => {
		const nextIdx = calculateNextIdx(categories);
		const newCategory = {category, idx: nextIdx};
		const response = await uploadNewCategory(newCategory, setError);
		if (response?.id) {
			const newCategoryWithId = {...newCategory, id: response.id};
			setCategories(prevCategories => [...prevCategories, newCategoryWithId]);
		}
	};

	const handleAddLink = async (e) => {
		e.preventDefault();
		if (!validateFormAddLink(formData)) return;

		const category = getFormattedValue(formData.category);
		const name = generateUniqueLinkName(links, getFormattedValue(formData.name), category);
		const url = formData.url;

		if (!categories.some(cat => cat.category === category)) {
			await handleAddCategory(category);
		}

		const imageUrl = await handleImageUpload(formData.image, setError);
		if (!imageUrl.empty) {
			const newLink = {category, name, url, image: imageUrl};
			const response = await addLink(newLink, setError);
			if (response?.id) {
				setLinks(prevLinks => [...prevLinks, {...newLink, id: response.id}]);
				setShowAddLink(false);
			}
		}
	};

	const handleChange = (e) => {
		const {name, value} = e.target;
		setFormData(prev => ({...prev, [name]: value}));

		if (name === 'category') {
			const searchTerm = value.toLowerCase().trim();
			const matchingCategories = categories.filter(cat =>
				cat?.category?.toLowerCase().includes(searchTerm)
			);
			const updatedSuggestions = [value, ...matchingCategories.map(cat => cat.category)];

			setSuggestions(updatedSuggestions);
			setShowSuggestions(searchTerm.length > 0 && matchingCategories.length > 0);
		}
	};

	const handleSuggestionClick = (suggestion) => {
		setFormData(prev => ({...prev, category: suggestion}));
		setShowSuggestions(false);
	};

	return (
		<div className="addLink-container">
			<div className="addLink-form">
				<button className="close-button" onClick={() => setShowAddLink(false)}>×</button>
				<h2>Add New Link</h2>
				<form onSubmit={handleAddLink}>
					<div className="form-group category-input-container">
						<input
							type="text"
							name="category"
							value={formData.category}
							onChange={handleChange}
							placeholder="Category"
							required
							autoComplete="off"
						/>
						{showSuggestions && (
							<ul className="category-suggestions">
								{suggestions.map((suggestion, index) => (
									<li key={`${suggestion}-${index}`}
									    onClick={() => handleSuggestionClick(suggestion)}>
										{suggestion}
									</li>
								))}
							</ul>
						)}
					</div>
					<p>
						<input
							type="text"
							name="name"
							placeholder="Link Name"
							value={formData.name}
							onChange={handleChange}
							required
						/>
					</p>
					<p>
						<input
							type="url"
							name="url"
							placeholder="URL"
							value={formData.url}
							onChange={handleChange}
							required
						/>
					</p>
					<p>
						<input
							type="file"
							name="image"
							placeholder="Image"
							onChange={(e) => setFormData(prev => ({
								...prev,
								image: e.target.files[0]
							}))}
						/>
					</p>
					<button type="submit">Add Link</button>
				</form>
			</div>
		</div>
	);
};

AddLink.propTypes = {
	categories: PropTypes.array.isRequired,
	setCategories: PropTypes.func.isRequired,
	links: PropTypes.array.isRequired,
	setLinks: PropTypes.func.isRequired,
	setShowAddLink: PropTypes.func.isRequired,
	setError: PropTypes.func.isRequired,
};

export default AddLink;
