import default_img from "./assets/default_img.png";

import {auth, db, deleteDoc, doc, query, storage, updateDoc, where} from "./firebase";
import {signInWithEmailAndPassword, signOut} from "firebase/auth";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {addDoc, collection, getDoc, getDocs} from "firebase/firestore";
import {getFormattedValue} from "./tools";

const fetchLinkRef = async (linkId, setError) => {
	try {
		return doc(db, 'links', linkId);
	} catch (error) {
		setError(`Error while fetching Link ref from Firebase: ${error.message}`);
	}
}

const fetchCategoryRef = async (categoryId, setError) => {
	try {
		return doc(db, 'categories', categoryId);
	} catch (error) {
		setError(`Error while fetching Category ref in Firebase: ${error.message}`);
	}
}

export const login = async (email, password, setError) => {
	try {
		return await signInWithEmailAndPassword(auth, email, password);
	} catch (error) {
		setError(
			error.code === 'auth/invalid-credential'
				? 'Invalid email or password'
				: 'An error occurred during login'
		);
	}
}

export const logout = async (setError) => {
	try {
		await signOut(auth);
		return true;
	} catch (error) {
		setError(`Error while logging out: ${error.message}`);
	}
}

export const fetchLinks = async (setError) => {
	try {
		return await getDocs(collection(db, 'links'));
	} catch (error) {
		setError(`Error while getting Links from Firebase: ${error.message}`)
	}
}

export const updateLink = async (linkId, linkData, setError) => {
	try {
		const linkRef = await fetchLinkRef(linkId, setError);
		await updateDoc(linkRef, linkData);
	} catch (error) {
		setError(`Error while updating Link in Firebase: ${error.message}`);
	}
};

export const uploadDefaultImage = async (setError) => {
	try {
		const response = await fetch(default_img);
		const blob = await response.blob();
		const defaultImageName = 'default_image.png';
		const storageRef = ref(storage, `image_link/${defaultImageName}`);
		await uploadBytes(storageRef, blob);
		return await getDownloadURL(storageRef);
	} catch (error) {
		setError(`Error while uploading default Image to Firebase: ${error.message}`)
	}
};

export const uploadNewImage = async (image, setError) => {
	try {
		const storageRef = ref(storage, `image_link/${image.name}`);
		const snapshot = await uploadBytes(storageRef, image);
		return await getDownloadURL(snapshot.ref);
	} catch (error) {
		setError(`Error while uploading new Image to Firebase: ${error.message}`)
	}
}

export const addLink = async (link, setError) => {
	try {
		return await addDoc(collection(db, 'links'), link);
	} catch (error) {
		setError(`Error while adding new Link to Firebase: ${error.message}`)
	}
}

export const deleteLink = async (linkId, setError) => {
	const linkRef = await fetchLinkRef(linkId, setError);
	try {
		await deleteDoc(linkRef);
		return true;
	} catch (error) {
		setError(`Error while removing Link from Firebase: ${error.message}`);
		return false;
	}
}

export const fetchLinksByCategory = async (category, setError) => {
	try {
		return await getDocs(query(collection(db, 'links'), where('category', '==', category)));
	} catch (error) {
		setError(`Error while fetching Links by Category from Firebase: ${error.message}`);
		return null;
	}
};

export const fetchCategories = async (setError) => {
	try {
		return await getDocs(collection(db, 'categories'));
	} catch (error) {
		setError(`Error while getting Categories from Firebase: ${error.message}`);
	}
}

export const fetchCategory = async (categoryId, setError) => {
	try {
		const ref = await fetchCategoryRef(categoryId, setError);
		return await getDoc(ref);
	} catch (error) {
		setError(`Error while fetching Category in Firebase: ${error.message}`);
	}
}

export const updateCategory = async (categoryObject, setError) => {
	if (!categoryObject?.id) {
		setError('Invalid category ID for update');
		return false;
	}

	const categoryData = {
		category: getFormattedValue(categoryObject.category),
		idx: categoryObject.idx
	};

	const categoryRef = await fetchCategoryRef(categoryObject.id, setError);
	try {
		await updateDoc(categoryRef, categoryData);
		return true;
	} catch (error) {
		setError(`Error while updating Category in Firebase: ${error.message}`);
		return false;
	}
};

export const uploadNewCategory = async (category, setError) => {
	try {
		return await addDoc(collection(db, "categories"), category);
	} catch (error) {
		setError(`Error while uploading new Category to Firebase: ${error.message}`);
	}
}

export const deleteCategory = async (categoryId, setError) => {
	const categoryRef = await fetchCategoryRef(categoryId, setError);
	try {
		await deleteDoc(categoryRef);
		return true;
	} catch (error) {
		setError(`Error while removing Category from Firebase: ${error.message}`);
		return false;
	}
}

export const removeLinksWhenCategoryRemoved = async (category, setError) => {
	const querySnapshot = await fetchLinksByCategory(category, setError);
	if (querySnapshot && querySnapshot.docs?.length > 0) {
		try {
			const deletePromises = querySnapshot?.docs?.map(doc => deleteDoc(doc.ref)) ?? [];

			await Promise.all(deletePromises);

			return true;
		} catch (error) {
			setError(`Error while removing Links on Firebase: ${error.message}`);
			return false;
		}
	}
	return false;
};

export const cleanUpDataBase = async (setError) => {
	const links = await fetchLinks(setError);
	if (links?.docs) {
		links.docs.forEach(link => deleteLink(link.id, setError));
	} else {
		setError(`No links found to be removed from Database`);
	}

	const categories = await fetchCategories(setError)
	if (categories?.docs) {
		categories.docs.forEach(category => deleteCategory(category.id, setError));
	} else {
		setError(`No categories found to be removed from DataBase`);
	}
}
