import './index.scss';

import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';

const EditLink = ({categories, link, onClose, onSave, onRemove}) => {
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [suggestions, setSuggestions] = useState([]);

	const [linkData, setLinkData] = useState({
		id: link.id,
		name: link.name,
		url: link.url,
		image: link.image,
		category: link.category
	});

	const handleChange = (e) => {
		const {name, value} = e.target;
		setLinkData(prev => ({
			...prev,
			[name]: value
		}));

		if (name === 'category') {
			const searchTerm = value.toLowerCase().trim();
			const matchingCategories = categories.filter(cat =>
				cat?.category?.toLowerCase().includes(searchTerm)
			);
			const updatedSuggestions = [value, ...matchingCategories.map(cat => cat.category)];

			setSuggestions(updatedSuggestions);
			setShowSuggestions(searchTerm.length > 0 && matchingCategories.length > 0);
		}
	};

	const handleSuggestionClick = (suggestion) => {
		setLinkData(prev => ({...prev, category: suggestion}));
		setShowSuggestions(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onSave({...link, ...linkData});
	};

	return (
		<Modal onClose={onClose}>
			<form onSubmit={handleSubmit} className="edit-link-form">
				<h2>Edit Link</h2>
				<div className="form-group">
					<label htmlFor="name">Name:</label>
					<input
						type="text"
						id="name"
						name="name"
						value={linkData.name}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="category">Category:</label>
					<input
						type="text"
						id="category"
						name="category"
						value={linkData.category}
						onChange={handleChange}
						required
					/>
					{showSuggestions && (
						<ul className="category-suggestions">
							{suggestions.map((suggestion, index) => (
								<li key={`${suggestion}-${index}`}
								    onClick={() => handleSuggestionClick(suggestion)}>
									{suggestion}
								</li>
							))}
						</ul>
					)}
				</div>
				<div className="form-group">
					<label htmlFor="url">URL:</label>
					<input
						type="url"
						id="url"
						name="url"
						value={linkData.url}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="image">Image URL:</label>
					<input
						type="file"
						name="image"
						placeholder="Image"
						onChange={(e) => setLinkData(prev => ({
							...prev,
							image: e.target.files[0]
						}))}
					/>
				</div>
				<div className="form-actions">
					<button type="submit" className="btn-save">Save</button>
					<button
						type="button"
						className="btn-remove"
						onClick={() => onRemove(link)}
					>
						Remove
					</button>
					<button
						type="button"
						className="btn-cancel"
						onClick={onClose}
					>
						Cancel
					</button>
				</div>
			</form>
		</Modal>
	);
};

EditLink.propTypes = {
	categories: PropTypes.array.isRequired,
	link: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		image: PropTypes.string.isRequired,
		category: PropTypes.string.isRequired
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired
};

export default EditLink;
