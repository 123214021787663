import './App.scss';

import React, {useEffect, useState} from 'react';

import Cookies from 'js-cookie';

import Header from './containers/Header';
import Body from './containers/Body';
import Footer from './containers/Footer';
import LoginWallpaper from './containers/LoginWallpaper';
import AddLink from "./components/AddLink";

import {getCategories, getLinks} from "./tools";
import {login, logout} from "./firebaseTools";

const App = () => {
	const [showLoginForm, setShowLoginForm] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [showAddLink, setShowAddLink] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [error, setError] = useState('');

	const [categories, setCategories] = useState([]);
	const [links, setLinks] = useState([]);

	const setCookies = (user) => {
		if (user) {
			Cookies.set('authUser', JSON.stringify({
				uid: user.uid,
				email: user.email
			}), {
				expires: 7,
				secure: true,
				sameSite: 'strict',
				path: '/'
			});
		} else {
			Cookies.remove('authUser', {path: '/'});
		}
	};

	useEffect(() => {
		const existingCookie = Cookies.get('authUser');
		if (existingCookie) {
			try {
				const userData = JSON.parse(existingCookie);
				setIsLoggedIn(!!userData);
			} catch (error) {
				setError(`Error parsing auth cookie: ${error.message}`);
				Cookies.remove('authUser', {path: '/'});
			}
		}
		if (isLoggedIn) {
			getCategories(setCategories, setError).then();
			getLinks(setLinks, setError).then();
		}
	}, [isLoggedIn]);

	const onLogin = async (email, password) => {
		const loggedIn = await login(email, password, setError);
		if (loggedIn?.user) {
			setCookies(loggedIn.user);
			setIsLoggedIn(true);
			setShowLoginForm(false);
		}
	};

	const handleLogout = async () => {
		const loggedOut = await logout(setError);
		if (loggedOut) {
			setIsLoggedIn(false);
			setCookies(null);
		}
	};

	return (
		<div className="app">
			{!isLoggedIn ? (
				<LoginWallpaper
					onLogin={onLogin}
					showLoginForm={showLoginForm}
					setShowLoginForm={setShowLoginForm}
					error={error}
				/>
			) : (
				<>
					<div className="app-header">
						<Header
							isEdit={isEdit}
							setIsEdit={setIsEdit}
							onLogout={handleLogout}
							setShowAddLink={setShowAddLink}
							showAddLink={showAddLink}
							setLinks={setLinks}
							setCategories={setCategories}
							setError={setError}
						/>
					</div>
					<div className="app-body">
						{showAddLink && (
							<AddLink
								categories={categories}
								setCategories={setCategories}
								links={links}
								setLinks={setLinks}
								setShowAddLink={setShowAddLink}
								setError={setError}
							/>
						)}
						<Body
							isEdit={isEdit}
							categories={categories}
							setCategories={setCategories}
							links={links}
							setLinks={setLinks}
							error={error}
							setError={setError}
						/>
					</div>
					<div className="app-footer">
						<Footer/>
					</div>
				</>
			)}
		</div>
	);
};

export default App;
